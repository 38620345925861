import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);

  return (
    <>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "60px" }}>
      <div style={{ textAlign: 'center' }}>
      <div style={{ color: '#FFCCCB', fontSize: 'smaller', padding: '8px', backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>
   🎉
NO GST | COMMISSION: 5% ◉ REFERRAL: 3% FOR ALL GAMES 🎉
      </div>
    </div>
    
      {WebSitesettings && WebSitesettings.CompanyAddress && (
  <div className="mt-2 container position-relative" style={{ maxWidth: '100%' }}>
    <div
  role="alert"
  className="fade d-flex align-items-center justify-content-between alert alert-warning show text-start"
style={{
  fontSize: '0.8rem',
  backgroundColor: 'light',
  animation: 'rainbowBorder 19s infinite', // Rainbow border animation
 borderRadius:'5px',
  border: '2px double #FFA500', // Default border color
  boxShadow: '0 0 10px rgba(0,0,0,0.2)' // Optional: add shadow for better visibility
}}
>

      <span>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="20"
      height="20"
      fill="red"
       
    >
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
    </svg>
     &nbsp; <span style={{ color: '#786322', fontWeight: 'bold' }}>
{WebSitesettings.CompanyAddress}</span>
      </span>
    </div>
  </div>
)}
<section className="games-section p-3">
  <div className="d-flex align-items-center games-section-title">
    Our Games
  </div>
  <div className="games-section-headline mt-2 mb-1">
    <picture>
      <img
        height="16px"
        width="16px"
        src="https://khelbro.com/images/global-purple-battleIcon.png"
        alt="Battle Icon"
      />
    </picture>
    &nbsp;is for Battles and&nbsp;
    <picture>
      <img
        height="16px"
        width="16px"
        src="https://khelbro.com/images/global-blue-tournamentIcon.png"
        alt="Tournament Icon"
      />
    </picture>
    &nbsp;is for Tournaments.
    <span>Know more here.</span>
  </div>
  <hr />

  <div className="games-window">
    <Link className="gameCard-container" to={`/Homepage/Ludo%20Classics`}>
      <span className="blink text-primary d-block text-right">◉ LIVE</span>
      <picture className="gameCard-image">
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/Images/LandingPage_img/firstLudo.webp"}
          alt="Classic Ludo"
        />
      </picture>
      <div className="gameCard-title">
        <span className="text-dark d-block text-right">◉ CLASSIC LUDO</span>
      </div>
      <div className="goverlay">
        <div className="text">Live</div>
      </div>
    </Link>

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}> */}
                <Link className="gameCard-container" to={``}>
      <span className="blink text-danger d-block text-right">◉ COMING SOON</span>
      <picture className="gameCard-image">
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/Images/LandingPage_img/secondLudo.webp"}
          alt="Popular Ludo"
        />
      </picture>
      <div className="gameCard-title">
        <span className="text-dark d-block text-right">◉ POPULAR LUDO</span>
      </div>
      <div className="goverlay">
        <div className="text">COMING SOON</div>
      </div>
    </Link>
{/*
    <Link className="gameCard-container" to={`/Homepage/Ludo%201%20Goti`}>
      <span className="blink text-Live d-block text-right">◉ LIVE</span>
      <picture className="gameCard-image">
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/Images/LandingPage_img/thirdLudo.webp"}
          alt="Ludo No Cut"
        />
      </picture>
      <div className="gameCard-title">
        <span className="blink text-success d-block text-right">◉ LUDO NO CUT</span>
      </div>
      <div className="goverlay">
        <div className="text">Live</div>
      </div>
    </Link>

    <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`}>
      <span className="blink text-primary d-block text-right">◉ LIVE</span>
      <picture className="gameCard-image">
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/Images/LandingPage_img/fourthLudo.webp"}
          alt="Ludo Ulta"
        />
      </picture>
      <div className="gameCard-title">
        <span className="blink text-success d-block text-right">◉ LUDO ULTA</span>
      </div>
      <div className="goverlay">
        <div className="text">LIVE</div>
      </div>
    </Link>*/}
  </div>
  <hr />
</section>
          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#!"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="/Images/LandingPage_img/Header_profile.jpg"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "56px", height: "56px" }}
                />
              </picture>
              <span
                style={{
                  color: "rgb(149, 149, 149)",
                  fontSize: "1em",
                  fontWeight: 400,
                }}
                className={!open ? "d-block" : "d-none"}
              >
                {" "}
                Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                ></i>
              ) : (
                <i
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <Link className="col-6" to="/term-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link className="col-6" to="/PrivacyPolicy">
                    Privacy Policy
                  </Link>
                  <Link className="col-6" to="/RefundPolicy">
                    Refund/Cancellation Policy
                  </Link>
                  <Link className="col-6" to="/contact-us">
                    Contact Us
                  </Link>
                  <Link className="col-6" to="/responsible-gaming">
                    Responsible Gaming
                  </Link>
                </div>
              </div>
            </Collapse>
            <hr></hr>
            <div className="footer-divider" />
            <div className="px-3 py-4">
              <div className="footer-text-bold">About Us</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : " "} is a
                real-money gaming product owned and operated by{" "}
                {WebSitesettings ? WebSitesettings.CompanyName : " "} ("
                {WebSitesettings ? WebSitesettings.WebsiteName : " "}" or "We"
                or "Us" or "Our").
              </div>
              <br />
              <div className="footer-text-bold">
                Our Business &amp; Products
              </div>
              <br />
              <div className="footer-text">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : " "} is a
                skill-based real-money gaming platform accessible only for our
                users in India. It is accessible on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={WebSitesettings ? WebSitesettings.CompanyWebsite : " "}
                >
                  {WebSitesettings ? WebSitesettings.CompanyWebsite : " "}
                </a>
                . On {WebSitesettings ? WebSitesettings.WebsiteName : " "},
                users can compete for real cash in Tournaments and Battles. They
                can encash their winnings via popular options such as Paytm
                Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.
              </div>
              <br />
              <div className="footer-text-bold">Our Games</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : " "} has a
                wide-variety of high-quality, premium HTML5 games. Our games are
                especially compressed and optimised to work on low-end devices,
                uncommon browsers, and patchy internet speeds.
              </div>
              <br />
              <div className="footer-text">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket , Ludo With Friends. If you
                have any suggestions around new games that we should add or if
                you are a game developer yourself and want to work with us,
                don't hesitate to drop in a line at{" "}
                
                !
              </div>
            </div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
