import React, { useEffect, useState } from 'react'
import css from "../css/Refer.module.css"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2';
import Header from '../Components/Header';
// import 'remixicon/fonts/remixicon.css'


const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState()
  const Cashheader = () => {
    let access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data)
        // console.log(res.data);
        Allgames(res.data.referral_code)
      }).catch((e) => {
        alert(e.msg)
      })

  }
  
  const [cardData, setGame] = useState([])

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl+`referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data)
console.log(res.data)

      })

  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
    const fetchData = async () => {
      const response = await fetch(baseUrl + "settings/data");
      const data = await response.json();
      return setWebsiteSettings(data);
    }

  useEffect(() => {
    Cashheader()
    fetchData();
//eslint-disable-next-line
  }, [])
  
  
    const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: 'center',
      icon: 'success',
      type: 'success',
      title: 'Room Code Copied',
      showConfirmButton: false,
      timer: 1200,
    

    });

  }

  if (user === undefined) {
    return null
  }

  return (
      <>
    <div
      className={css.mainArea}
      style={{ paddingTop: '15px', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}
    >
      <div style={{ width: '100%', background: '#f9f9f9', borderRadius: '15px', padding: '20px', border: '1px solid #ddd', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)' }}>
        <div className="text-center mb-4">
          <picture>
            <img alt="img" width="120px" src={process.env.PUBLIC_URL + 'Images/refer/refer.png'} className="snip-img" />
          </picture>
          <div className="mt-3 font-18" style={{ fontWeight: 'bold', color: '#333' }}>
            Earn Now, Unlimited <span aria-label="party-face"> 🥳</span>
          </div>
          <div className="font-14" style={{ color: '#666' }}>
            Refer your friends now!
          </div>
        </div>

        <div className="text-center font-12" style={{ color: '#007bff', marginBottom: '10px' }}>
          Current Earning: <b>{user.referral_wallet}</b>
          <Link className="ml-2" to="/Redeem" style={{ color: '#28a745' }}>Redeem</Link>
        </div>
        <div className="text-center font-12" style={{ color: '#333', marginBottom: '20px' }}>
          Total Earned: <b>{user.referral_earning}</b>
        </div>

        <div className="progress" style={{ background: '#e9ecef', borderRadius: '10px', height: '8px', overflow: 'hidden', marginBottom: '15px' }}>
          <div style={{ width: `${user.referral_earning * 300 / 10000}%`, background: '#28a745', height: '100%' }}></div>
        </div>

        <div className="font-12 mb-3" style={{ color: '#333' }}>
          <span>Max: ₹10,000</span>
          <Link className="float-right" to="/update-pan" style={{ color: '#17a2b8' }}>Upgrade Limit</Link>
        </div>

        <div className="text-center" style={{ marginBottom: '20px' }}>
          <div style={{ fontWeight: 'bold', color: '#555' }}>
            Your Refer Code: {user.referral_code}
          </div>
<button 
        onClick={() => navigator.clipboard.writeText(`https://haryanaludo.com/login/${user.referral_code} Register Now, My refer code is ${user.referral_code}.`)} 
        style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' }}
      >
        Copy Referral Code Link
      </button>
        </div>

        <div className="d-flex justify-content-center font-14" style={{ color: '#333', marginBottom: '20px' }}>
          Total Refers: <b>{cardData && cardData}</b>
        </div>

        <div className="card p-2 shadow-sm mb-3" style={{ borderRadius: '10px', background: '#fff', border: '1px solid #ddd', boxShadow: '0px 4px 10px rgba(0,0,0,0.1)' }}>
          <div className="font-11 text-bold" style={{ color: '#555', marginBottom: '10px' }}>
            Refer & Earn Rules
          </div>
          <div className="d-flex align-items-center mb-2">
            <picture>
              <img alt="img" width="50px" src={process.env.PUBLIC_URL + 'Images/refer/giftbanner.png'} className="snip-img" />
            </picture>
            <div className="font-9 mx-2" style={{ width: '70%', color: '#777' }}>
              When your friend signs up on Our website or App from your referral link, you get <strong>2% Commission</strong> on your referral's winnings.
            </div>
          </div>
          <div className="d-flex align-items-center">
            <picture>
              <img alt="img" width="50px" src={process.env.PUBLIC_URL + 'Images/refer/banner.png'} className="snip-img" />
            </picture>
            <div className="font-9 mx-2" style={{ width: '70%', color: '#777' }}>
              Suppose your referral plays a battle for ₹10000 Cash, you get <strong>₹300 Cash</strong>.
            </div>
          </div>
        </div>

        <div className="text-center mb-2">
          <div className="d-flex justify-content-between">
            <a href={`whatsapp://send?text=Play Ludo and earn ₹10000 daily. https://haryanaludo.com/login/${user.referral_code} Register Now, My refer code is ${user.referral_code}.`} style={{ width: "32%" }}>
              <button className="refer-button cxy" style={{ backgroundColor: '#28a745', color: '#fff', padding: '5px 0', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)' }}>
                WhatsApp
              </button>
            </a>
            <a href={`https://www.facebook.com/`} style={{ width: "32%" }}>
              <button className="refer-button cxy" style={{ backgroundColor: '#1877f2', color: '#fff', padding: '5px 0', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)' }}>
                Facebook
              </button>
            </a>
            <a href={`https://telegram.me/`} style={{ width: "32%" }}>
              <button className="refer-button cxy" style={{ backgroundColor: '#0088cc', color: '#fff', padding: '5px 0', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)' }}>
                Telegram
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    </>
  )
}

export default Refer
