import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios"



const Transactionhistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  //eslint-disable-next-line
  const [user, setUser] = useState()
  //use for pagination..
    let limit = 50;
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data)
        
        //Allgames(res.data._id)
        Allgames(res.data._id,pageNumber,limit)
        // window.location.reload()

      })

  }

 const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  
const dateFormat=(e)=>{
      
  const date = new Date(e); 
const newDate = date.toLocaleString('default', { month: 'long',day:'numeric',hour:'numeric',hour12:true,minute:'numeric' });
return newDate;
  }

  const [cardData, setGame] = useState()

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl+`temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers })
      .then((res) => {
        setGame(res.data.ress)
        setNumberOfPages(res.data.totalPages);

      })

  }


  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [pageNumber,limit])


  return (
<div className="container-fluid p-0">
  <div className="leftContainer min-vh-100 d-flex flex-column">
    {/* Pagination */}
    <div className="pt-5">
      <div className="mt-4">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={numberOfPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>

    {/* Game Cards */}
    {cardData && cardData.length > 0 ? (
      cardData.map((card) => {
        const id = card._id.toString();
        const timestamp = parseInt(id.slice(0, 8), 16);
        const counterid = timestamp;
        const dateStr = dateFormat(card.createdAt).split(',');

        let titleMsg, signIcon;
        switch (card.Req_type) {
          case 'deposit':
          case 'bonus':
            titleMsg = 'Cash added';
            signIcon =
              card.status === 'Pending' ? (
                <div className="text-danger">(X)</div>
              ) : (
                <div className="text-success">(+)</div>
              );
            break;
          case 'withdraw':
            titleMsg = `Withdraw using ${card.Withdraw_type}`;
            signIcon = <div className="text-danger">(-)</div>;
            break;
          case 'penalty':
            titleMsg = 'Penalty';
            signIcon = <div className="text-danger">(-)</div>;
            break;
          default:
            titleMsg = card.status === 'FAILED' ? 'FAILED' : '';
            signIcon = <div className="text-danger">(X)</div>;
            break;
        }

        return (
          <div
            className={`card w-100 mt-2 p-2 d-flex flex-row align-items-center ${css.list_item}`}
            key={id}
            style={{ borderRadius: "10px", backgroundColor: "#f8f9fa", boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="d-flex align-items-center mr-3">
              <img
                height="24px"
                width="24px"
                src={`${process.env.PUBLIC_URL}/Images/LandingPage_img/ludo.jpeg`}
                alt=""
                style={{ borderRadius: "5px" }}
              />
            </div>

            <div className="d-flex flex-column flex-grow-1">
              <div className="d-flex justify-content-between w-100">
                <b>{titleMsg}</b>
                <small>{dateStr[0]}</small>
              </div>
              <div>
                <h6 className="mb-1">OrderID: JP-{counterid}</h6>
                <div className={`${css.games_section_headline}`} style={{ fontSize: "0.75em" }}>
                  Status:{" "}
                  {card.status === "none" ||
                  (card.status === "Pending" && card.Req_type === "withdraw")
                    ? "Processing"
                    : card.status === "Pending" && card.Req_type === "deposit"
                    ? "Cancelled"
                    : card.status}
                  {card.txn_msg && <><br />{card.txn_msg}</>}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-end">
              <div className="d-flex align-items-center">
                {signIcon}
                <img
                  alt="Rupee Icon"
                  height="18px"
                  width="18px"
                  src={`${process.env.PUBLIC_URL}/Images/LandingPage_img/global-rupeeIcon.png`}
                  className="ml-1"
                />
                <span className="pl-1">{card.amount}</span>
              </div>
              {card.closing_balance && (
                <div
                  className={`${css.games_section_headline}`}
                  style={{ fontSize: "0.6em", whiteSpace: 'nowrap' }}
                >
                  Closing balance: {card.closing_balance}
                </div>
              )}
            </div>
          </div>
        );
      })
    ) : (
      <div className="text-center">
        <img
          src={`${process.env.PUBLIC_URL}/Images/notransactionhistory.png`}
          alt="No Data"
          width={300}
          height={300}
          className="img-fluid"
          style={{ marginTop: "25%" }}
        />
        <div className="mt-2">
          <h3 className="font-weight-bold">No Transaction History</h3>
          <p className="text-muted">You have not made any transactions yet.</p>
        </div>
      </div>
    )}
  </div>
</div>


  );
};

export default Transactionhistory;
